import React from 'react'
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  jumbo: {
    height: "100vh"
  },
}));

export const query = graphql`
query {
  jumbo: file(relativePath: { eq: "aboutPageHeader.jpg" }) {
    childImageSharp {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`

const About = ({ data }) => {

  const classes = useStyles();

  return(
  <Layout>
    <SEO title="About Page" />
    <BackgroundImage 
                  Tag="section"
                  className={classes.jumbo}
                  fluid={data.jumbo.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  >
            <div class="jumbo-inner-wrapper">
                <h1 class="jumbo-title-normal">
                    Found Stages builds community <br></br>
                    through innovative storytelling
                </h1>
            </div>
        </BackgroundImage>
        {/* <Grid container spacing={2} class="body-wrapper"> */}
        <div class="body-wrapper">
        <div class="about-title-wrapper">
              <div class="productions-title">
                  What We Do
              </div>
          </div>
            <div class="content-row top-row">
                <div class="content-block about-pic-left">
                    {/* <img src={about1} class="content-pic"/> */}
                </div>
                <div class="content-block text-block">
                    <h2>Found Stages has been taking plays out of theaters and into real-world spaces since 2014.
We connect stories directly to audiences in their own backyards (sometimes literally!). <br></br><br></br>

Beyond live events, we push the boundaries of theatrical storytelling by merging art and technology to bring theater to audiences wherever they are – via their smartphones.</h2>
                </div>
            </div>
            <div class="content-row bottom-row">
                <div class="content-block text-block">
                    <h2>Plays were once the thing that drew people out of their houses and into their communities to connect with their neighbors.
We think plays can be that thing again. <br></br><br></br>
That’s why there’s so often food at our events. We think sharing stories and food leads to a shared understanding of those around us.
<br></br><br></br>
In other words, you may enter our shows in a group of strangers, but it’s our hope that you’ll leave as neighbors.</h2>
                </div>
                <div class="content-block about-pic-right">
                    {/* <img src={about2} class="content-pic"/> */}
                </div>
            </div>
            </div>
        {/* </Grid> */}
  </Layout>
  )
}

export default About
